import React, { useState, useEffect } from "react";
import { Card, Table, Button, Tooltip, Select, Tag } from "antd";
import { listBikes } from "../../../graphql/queries"; // Assuming listBikes query exists
import { generateClient } from "aws-amplify/api";
import { useNavigate } from "react-router-dom";
import downloadExcel from "../../../components/download";
import { DownloadOutlined } from '@ant-design/icons';
import SearchComponent from "../../../components/searchBar";
import { fetchAuthSession, fetchUserAttributes } from '@aws-amplify/auth';

const client = generateClient();

const Bikes = () => {
  const [bikes, setBikes] = useState([]);
  const [filteredBikes, setFilteredBikes] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [franchises, setFranchises] = useState([]);
  const [selectedFranchise, setSelectedFranchise] = useState(null);
  const navigate = useNavigate();

  const renderBikeStatus = (status) => {
    if (status === "active") {
      return <Tag color={"green"}>{status}</Tag>;
    }
    if (status === "inactive") {
      return <Tag color={"orange"}>{status}</Tag>;
    }
    if (status === "maintenance") {
      return <Tag color={"red"}>{status}</Tag>;
    }
  };

  useEffect(() => {
    fetchBikes();
    checkUserRole();
  }, [client]);

  const extractFranchises = (bikes) => {
    const uniqueFranchises = [...new Set(bikes.map(bike => bike.franchise))];
    setFranchises(uniqueFranchises);
  };

  async function fetchBikes() {
    let allBikes = [];
    let nextToken = null;
    do {
      const bikeData = await client.graphql({
        query: listBikes,
        variables: { limit: 1000, nextToken: nextToken }
      });
      allBikes = [...allBikes, ...bikeData.data.listBikes.items];
      nextToken = bikeData.data.listBikes.nextToken;
    } while (nextToken);

    setBikes(allBikes);
    setFilteredBikes(allBikes);
    extractFranchises(allBikes);
  }

  const handleFranchiseChange = (value) => {
    setSelectedFranchise(value);
    if (value) {
      const filtered = bikes.filter(bike => bike.franchise === value);
      setFilteredBikes(filtered);
    } else {
      setFilteredBikes(bikes);
    }
  };

  const handleSearch = (searchTerm) => {
    const filtered = bikes.filter(bike =>
      bike.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      bike.bikeType.toLowerCase().includes(searchTerm.toLowerCase()) ||
      bike.status.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredBikes(filtered);
  };

  async function checkUserRole() {
    try {
      const session = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();

      const isAdminUser = userAttributes['custom:isAdmin'] === 'true';

      const groups = session.tokens.accessToken.payload['cognito:groups'];
      const isInAdminGroup = groups && groups.includes('Admins');
      const isInOperatorsGroup = groups && groups.includes('Operators');

      setIsAdmin(isAdminUser || isInAdminGroup);
    } catch (error) {
      console.error("Error checking user role:", error);
    }
  }

  const tableColumns = [
    {
      title: "Bike Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Bike Type",
      dataIndex: "bikeType",
      key: "bikeType",
    },
    {
      title: "Fleet",
      dataIndex: "fleet",
      key: "fleet",
    },
    {
      title: "Location",
      dataIndex: "franchise",
      key: "franchise",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => renderBikeStatus(status),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          padding: 20,
        }}
      >
        <h2>Bikes</h2>
        <SearchComponent
          placeholder="Search by bike name, type, status"
          onSearch={handleSearch}
        />
        {isAdmin && (
          <Button type="primary" onClick={() => navigate("/add-bike")}>
            Add Bike
          </Button>
        )}
      </div>

      <Card title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <span>Bikes List</span>
          <Select
            style={{ width: 200, marginRight: 20 }}
            placeholder="Filter by franchise"
            onChange={handleFranchiseChange}
            allowClear
          >
            {franchises.map(franchise => (
              <Select.Option key={franchise} value={franchise}>{franchise}</Select.Option>
            ))}
          </Select>
        </div>
      } extra={isAdmin && (
        <Tooltip title="Download as Excel">
          <DownloadOutlined onClick={() => downloadExcel(filteredBikes, "bikes")}
            style={{ fontSize: '20px', cursor: 'pointer' }}
          />
        </Tooltip>)}
        style={{ margin: 20 }}>
        <Table dataSource={filteredBikes} columns={tableColumns} rowKey="id"
          onRow={(bikeItem) => ({
            onClick: () => {
              navigate(`bike/${bikeItem.id}`);
            },
          })}
        />
      </Card>
    </div>
  );
};

export default Bikes;
